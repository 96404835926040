import React from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery,graphql } from "gatsby"
// import Image from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

const delay = props => {
  return keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: ${props};
    }
  `
}

const Style = styled.div`
  background: ${props => props.background || "#999"};
  background: linear-gradient(45deg, #7bc6e2 0%, #fdb6c6 50%, #ffbe74 100%);
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "400px"};
  position: fixed;
  top: 0;
  left: 0;
  #bg {
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "400px"};
    overflow: hidden;
    opacity: 0;
    animation: 0.4s ${delay(1)} linear 0s forwards;
    position: relative;
    z-index: 1;
    .gatsby-image-wrapper {
      width: 100vw;
      height: 100%;
      img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: linear-gradient(45deg, #7bc6e2 0%, #fdb6c6 50%, #ffbe74 100%);
      opacity: 1;
      z-index: 999;
      mix-blend-mode: overlay;
    }
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: linear-gradient(45deg, #5433FF 0%, #20BDFF 50%, #A5FECB 100%);
      opacity: 0.6;
      z-index: 9999;
      mix-blend-mode: multiply;
    }
  }
`
const Polygon = props => {
  const data = useStaticQuery(graphql`
    query AuthQuery {
      avatar: file(absolutePath: { regex: "/logo2.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 80,height: 80)
        }
        publicURL
      }
      backgroundImage: file(absolutePath: { regex: "/qbkls-l.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 2000)
        }
        publicURL
      }
    }
  `)
  return (
    <Style
      className="polygon"
      width={props.width}
      height={props.height}
      background={props.background}
    >
      <div id="bg">
        <GatsbyImage
          // image={data.backgroundImage.childImageSharp.fluid}
          image={data.backgroundImage.childImageSharp.gatsbyImageData}
          style={{ width: "100%", height: "400px" }}
          // style={{ width: "100%", height: "100%" }}
          objectFit="cover"
          alt="background"
        />
      </div>
    </Style>
  )
}

export default Polygon
